<template>
  <v-dialog max-width="800px" persistent :value="dialog">
    <v-card>
      <v-card-title primary-title class="headline primary white--text">
        {{ $tc("uml.enum.name", 1) }}
      </v-card-title>

      <v-card-text>
        <v-form ref="enumForm" v-model="validForm">
          <v-container fluid>
            <v-row align="center" no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  :rules="[
                    (v) => !!v || $t('validation.required'),
                    (v) => v !== 'Enum' || $t('uml.form.validation.enum_name'),
                    (v) =>
                      !v ||
                      v.search(' ') === -1 ||
                      $t('uml.form.validation.space_name'),
                  ]"
                  :label="$t('uml.form.enum_name')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <span class="title">{{ $t("uml.form.values") }}</span>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn icon @click="addValue()">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(att, index) in form.values"
                :key="index"
              >
                <v-icon class="pointer" @click="removeValue(att)">
                  delete
                </v-icon>
                <input
                  :ref="index"
                  type="text"
                  v-model="att.value"
                  :placeholder="$t('uml.form.enum_value')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" :disabled="!formIsValid">
          {{ $t("save") }}
        </v-btn>
        <v-btn class="warning" @click="close()">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="error" @click="deleteEnumDialog = true">
          {{ $t("remove") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- dialog delete entity -->
    <modal-dialog
      @cancel="deleteEnumDialog = false"
      @submit="removeEnum()"
      :dialog="deleteEnumDialog"
      :title="$t('uml.dialog.enum.delete_title')"
      titleClass="error white--text"
      titleIcon="warning"
      submitClass="error"
      :submitText="$t('delete')"
      :content="$t('uml.dialog.enum.delete_dialog')"
    ></modal-dialog>
  </v-dialog>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import graphHelper from "../graph.helper";
import ModalDialog from "@/components/common/ModalDialog";

export default {
  components: { ModalDialog },
  data() {
    return {
      validForm: false,
      deleteEnumDialog: false,
      form: {},
    };
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    model: {
      validator: (prop) => typeof prop === "object" || prop === null,
      required: true,
    },
  },
  computed: {
    formIsValid() {
      if (!this.validForm) return false;
      if (this.form.values.find((p) => p.value.trim() == "")) return false;
      return true;
    },
  },
  watch: {
    model() {
      if (!this.model) return;

      this.form = {
        id: this.model.getId(),
        name: this.model.getName(),
        values: cloneDeep(
          this.model.getValues().map((e) => {
            return {
              value: e,
            };
          })
        ),
      };

      this.$nextTick(() => this.$refs.enumForm.validate());
    },
  },
  methods: {
    _isValid() {
      if (graphHelper.nameIsUsed(this.form)) {
        this.$notify({
          type: "error",
          text: this.$t("uml.notification.name"),
        });
        return false;
      }
      for (const x of this.form.values) {
        for (const y of this.form.values) {
          if (x !== y && x.value === y.value) {
            this.$notify({
              type: "error",
              text: this.$t("uml.notification.duplicated_values"),
            });
            return false;
          }
        }
      }
      return true;
    },
    save() {
      if (!this._isValid()) return;
      this.model.setName(this.form.name);
      this.model.setValues(this.form.values.map((p) => p.value));
      graphHelper.refreshGraphCells();
      this.close();
    },
    close() {
      if (this.model.getName() === "Enum") this.removeEnum();
      else this.$emit("close");
    },
    removeEnum() {
      this.model.remove();
      this.$emit("close");
      this.deleteEnumDialog = false;
    },
    addValue() {
      this.form.values.push({ value: "" });
      this.$nextTick(() =>
        // focus en la última referencia que aún tenga un input
        this.$refs[
          Object.keys(this.$refs)
            .reverse()
            .find((e) => this.$refs[e][0])
        ][0].focus()
      );
    },
    removeValue(value) {
      this.form.values.splice(this.form.values.indexOf(value), 1);
    },
  },
};
</script>
