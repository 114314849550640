var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.feature.hidden
    ? _c(
        "div",
        [
          _c(
            "v-layout",
            [
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.expandable, icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.switchExpand()
                    },
                  },
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.icon))])],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "", disabled: !_vm.description },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-checkbox",
                              {
                                attrs: { disabled: _vm.feature.mandatory },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateSelection()
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.description
                                      ? {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c("v-icon", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(_vm.descriptionIcon)
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        }
                                      : null,
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.innerFeature.selected,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.innerFeature, "selected", $$v)
                                  },
                                  expression: "innerFeature.selected",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: _vm.featureClass,
                                    attrs: { slot: "label" },
                                    slot: "label",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.label) + " ")]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    694069072
                  ),
                },
                [_vm._v(" " + _vm._s(_vm.description) + " ")]
              ),
            ],
            1
          ),
          _vm.feature.features && _vm.feature.expanded
            ? _c(
                "div",
                { staticClass: "feature-children" },
                _vm._l(_vm.feature.features, function (c) {
                  return _c("feature", {
                    key: c.name,
                    attrs: { feature: c },
                    on: { change: (newVal) => (c = newVal) },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }