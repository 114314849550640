var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { "max-width": "800px", persistent: "", value: _vm.dialog } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline primary white--text",
              attrs: { "primary-title": "" },
            },
            [_vm._v(" " + _vm._s(_vm.$t("uml.relationship")) + " ")]
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "relationshipForm",
                  model: {
                    value: _vm.validForm,
                    callback: function ($$v) {
                      _vm.validForm = $$v
                    },
                    expression: "validForm",
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("uml.form.source"))),
                            ]),
                          ]),
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("span", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("uml.form.target"))),
                            ]),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: _vm.$tc("entity.name", 1),
                                  items: _vm.entities,
                                  "item-text": "attributes.name",
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.source,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "source", $$v)
                                  },
                                  expression: "form.source",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: _vm.$tc("entity.name", 1),
                                  items: _vm.entities,
                                  "item-value": "attributes",
                                  "item-text": "attributes.name",
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.target,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "target", $$v)
                                  },
                                  expression: "form.target",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("uml.form.label"),
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                    (v) =>
                                      !v ||
                                      _vm.reservedWords.indexOf(v) === -1 ||
                                      _vm.$t(
                                        "uml.form.validation.reserved_words"
                                      ),
                                    (v) =>
                                      !v ||
                                      v.search(" ") === -1 ||
                                      _vm.$t("uml.form.validation.space_name"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.sourceOpts.label,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.sourceOpts, "label", $$v)
                                  },
                                  expression: "form.sourceOpts.label",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t("uml.form.label"),
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                    (v) =>
                                      !v ||
                                      _vm.reservedWords.indexOf(v) === -1 ||
                                      _vm.$t(
                                        "uml.form.validation.reserved_words"
                                      ),
                                    (v) =>
                                      !v ||
                                      v.search(" ") === -1 ||
                                      _vm.$t("uml.form.validation.space_name"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.targetOpts.label,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.targetOpts, "label", $$v)
                                  },
                                  expression: "form.targetOpts.label",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t("uml.form.multiplicity"),
                                  items: _vm.possibleMultiplicity,
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.sourceOpts.multiplicity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.sourceOpts,
                                      "multiplicity",
                                      $$v
                                    )
                                  },
                                  expression: "form.sourceOpts.multiplicity",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t("uml.form.multiplicity"),
                                  items: _vm.possibleMultiplicity,
                                  rules: [
                                    (v) => !!v || _vm.$t("validation.required"),
                                  ],
                                },
                                model: {
                                  value: _vm.form.targetOpts.multiplicity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.targetOpts,
                                      "multiplicity",
                                      $$v
                                    )
                                  },
                                  expression: "form.targetOpts.multiplicity",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.validForm },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "warning",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }